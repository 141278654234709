import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import InfoView from '../views/InfoView.vue';
import WikiOverview from '../views/wiki/WikiOverview.vue';
import WikiInstallation from '../views/wiki/WikiInstallation.vue';
import WikiFeatures from '../views/wiki/WikiFeatures.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/info',
    name: 'info',
    component: InfoView
  },
  {
    path: '/wiki',
    component: WikiOverview, // Hauptseite für das Wiki
    children: [
      {
        path: '',
        name: 'wiki-overview',
        component: WikiOverview
      },
      {
        path: 'installation',
        name: 'wiki-installation',
        component: WikiInstallation
      },
      {
        path: 'features',
        name: 'wiki-features',
        component: WikiFeatures
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory('/'),
  routes
});

export default router;
