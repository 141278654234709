<template>
  <div class="container mt-4">
    <h1 class="text-center mb-4">📖 Under Construction............... (Loki Work Manager WIKI)</h1>
    <ul class="inha">
      <li class="linkr"><router-link to="/wiki/installation">Erste Einrichtung</router-link></li>
      <li><router-link to="/wiki/features">Funktionen</router-link></li>
    </ul>
    <router-view />
  </div>
</template>
<style lang="scss" scoped>

.inha {
  color: #D1D1D1;
  text-align: left;
  margin-top: 15px;
  text-decoration: none;
}

.linkr {
  text-decoration: none;
} 
</style>
