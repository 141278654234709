<template>
  <div class="container mt-4 con">
    <h1 class="text-center mb-4">Willkommen beim Loki Work Manager</h1>
    <h2 class="text-center mb-3">Hier findest du die aktuellsten Informationen zu meinem Programm.</h2>
    <div class="spacer"></div>
    <div class="row">
      <div class="col-12 mb-3 cards1" v-for="(card, index) in cards" :key="index">
        <div class="card shadow-sm">
          <div class="card-body">
            <h5 class="card-title">{{ card.title }}</h5>
            <p class="card-text">{{ card.description }}</p>
            <router-link v-if="card.linkType === 'internal'" :to="card.link" class="btn btn-primary">Mehr Infos</router-link>
            <a v-if="card.linkType === 'external'" :href="card.link" target="_blank" class="btn btn-primary">Mehr Infos</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const cards = ref([
  { title: 'Neues Update!', description: 'Das neue Update ist Live, Infos was geändert wurde', linkType: 'internal', link: '/info' },
  { title: 'Feedback!', description: 'Hier geht es zum Feedback, deine Meinung ist es Wert!', linkType: 'external', link: 'https://docs.google.com/spreadsheets/d/1HrMItjy9MzCBPnk7EWvY4cnNj7Is0Vd_YmQFc3Jdek4/edit?usp=sharing' },
  { title: 'Wartungsarbeit!', description: 'Zurzeit sind keine Wartungsarbeiten bekannt' }
]);
</script>
<style lang="scss">

.con {
  background-color: transparent;
}

.card-title {
  font-style: bold;
  font-size: 25px;
}

.card-text {
  font-style: italic;
  font-size: 16px;
}

h1 {
  color: #D1D1D1;
  font-style: bold;
  font-size: 46px;
}

h2 {
  color: #D1D1D1;
  font-style: italic;
  font-size: 18px;
}

.card-body {
  background-color: #2E2E2E;
  border: solid 2px #8C7051;
  border-radius: 8px ;
  color: #D1D1D1;
}

.col-12 {
  padding: 5px;

}

.btn-primary {
  background-color: #8C7051;
  border: solid 2px #1E1E1E;
  border-radius: 8px;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  
  &:hover {
    background-color: #A18262; 
    border: solid 2px #1E1E1E;
    transform: translateY(-3px); 
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2); 
  }

  &:active {
    transform: translateY(2px) scale(0.98); 
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
}

.spacer {
  height: 70px;
}

</style>