import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container mt-4 con" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "card shadow-sm" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "card-text" }
const _hoisted_7 = ["href"]

import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const cards = ref([
  { title: 'Neues Update!', description: 'Das neue Update ist Live, Infos was geändert wurde', linkType: 'internal', link: '/info' },
  { title: 'Feedback!', description: 'Hier geht es zum Feedback, deine Meinung ist es Wert!', linkType: 'external', link: 'https://docs.google.com/spreadsheets/d/1HrMItjy9MzCBPnk7EWvY4cnNj7Is0Vd_YmQFc3Jdek4/edit?usp=sharing' },
  { title: 'Wartungsarbeit!', description: 'Zurzeit sind keine Wartungsarbeiten bekannt' }
]);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "text-center mb-4" }, "Willkommen beim Loki Work Manager", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "text-center mb-3" }, "Hier findest du die aktuellsten Informationen zu meinem Programm.", -1)),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "spacer" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cards.value, (card, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-12 mb-3 cards1",
          key: index
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h5", _hoisted_5, _toDisplayString(card.title), 1),
              _createElementVNode("p", _hoisted_6, _toDisplayString(card.description), 1),
              (card.linkType === 'internal')
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: card.link,
                    class: "btn btn-primary"
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("Mehr Infos")
                    ])),
                    _: 2
                  }, 1032, ["to"]))
                : _createCommentVNode("", true),
              (card.linkType === 'external')
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    href: card.link,
                    target: "_blank",
                    class: "btn btn-primary"
                  }, "Mehr Infos", 8, _hoisted_7))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

})