<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/info">Info</router-link> |
    <router-link to="/wiki">WIKI</router-link>
  </nav>
  <router-view/>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #8C7051;
  background-color: #1E1E1E; // Hintergrundfarbe ändern
  min-height: 100vh; // Damit der Hintergrund die ganze Seite bedeckt
  display: flex;
  flex-direction: column;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #777575;

    &.router-link-exact-active {
      color: #8C7051;
    }
  }
}
</style>
