<template>
  <div class="container mt-4">
    <h1 class="text-center mb-4">Update Infos</h1>
    <div class="spacer2"></div>
    <div class="card shadow-sm p-4">
      <h3>Update 1.0</h3>
      <div class="mt-3">
        <h5>Microsoft Verknüpfung:</h5>
        <ul>
          <li>Microsoft Verknüpfung funktioniert wieder.</li>
        </ul>
      </div>
      <div class="mt-3">
        <h5>User Interface:</h5>
        <ul>
          <li>Die Darstellung wurde verbessert</li>
        </ul>
      </div>
      <div class="mt-3">
        <h5>Analytics:</h5>
        <ul>
          <li>Totalanzeige verbessert.</li>
          <li>Grafik verbessert.</li>
          <li>Gesamtanzeige überarbeitet.</li>
        </ul>
      </div>
      <div class="mt-3">
        <h5>Timer:</h5>
        <ul>
          <li>Der Timer schliesst sich nun selbständig nach dem speichern.</li>
        </ul>
      </div>
      <div class="mt-3">
        <h5>Notizen:</h5>
        <ul>
          <li>Übersicht angepasst.</li>
          <li>Notiz maximal Länge verbessert.</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="spacer3"></div>
</template>

<style lang="scss">
.container {
  max-width: 800px;
}
.card {
  background-color: #2E2E2E;
  border-radius: 10px;
  border: solid 1px #8C7051;
}
h1 {
  color: #D1D1D1;
}

h3 {
  color: #D1D1D1;
}

.mt-3 {
  color: #D1D1D1;
  text-align: left;
}

.update-section {
  color: #D1D1D1;
  text-align: left;
  margin-top: 15px;
}

.spacer2 {
  height: 10px;
}

.spacer3 {
  height: 65px;
}
</style>
